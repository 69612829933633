<template>
 <div>
   <transition name="bounce">
     <div class="portfolio_fullscreen" v-show="showVideo">
       <template v-if="data">

         <template v-if="typeof data.link === 'string' && data.link">
           <youtube v-if="data.link && showVideo" :video-id="videoId"></youtube>
         </template>

         <template v-else-if="typeof data.link === 'object' && data.link">
           <youtube v-if="showVideo && data.link.type === 'youtube'" :video-id="videoId"></youtube>

           <iframe v-else-if="data.link.type === 'vimeo'" :src="data.link.url.replace('//vimeo.com/', '//player.vimeo.com/video/')" class="modal__video__mp" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>

           <video  v-if="data.link.type === 'video'" class="modal__video__mp" tabindex="0" playsinline muted controls
                   preload="preload"
           >
             <source type="video/mp4;" :src="data.link.url"/>
           </video>
         </template>
         <template v-else>
           <video  v-if="data.video" class="modal__video__mp" tabindex="0" playsinline muted controls
                   preload="preload"
           >
             <source type="video/mp4;" :src="data.video"/>
           </video>
           <img v-if="data.image" :src="data.image.link"
                :alt="data.image.alt" class="fullImage"
                loading="lazy"
           >
         </template>
         <img src="@/assets/img/icons/close.svg" loading="lazy" class="closePopUp" @click="closeVideoPopup(true)" alt="close">
       </template>
     </div>

   </transition>

 </div>
</template>

<script>
import axios from "axios";


export default {
  name: "ModalVideoYouTube",
  props: {
    videoId: null,
    imageFull: null,
  },
  data (){
    return{
      showVideo: false,
      data: null
    }
  },
  watch: {
    /*'$route.query.videoid': {
      handler: function() {
        if (!this.$route.query.videoid && !this.$route.query.imageid) {
          if(this.showVideo){
            this.closeVideoPopup();
            this.closeVideoPopup();
          }
        } else {
          this.showVideo = true;
        }
      },
      deep: true,
      immediate: true
    },*/
    '$route.params.slug': {
      handler: function() {
        if (!this.$route.params.slug) {
          if(this.showVideo){
            this.closeVideoPopup();
            this.closeVideoPopup();
          }
        } else {
          this.showVideo = true;
        }
      },
      deep: true,
      immediate: true
    },
  },
  created() {
      axios
          .get(`https://back.digitalbeautywork.com/api/${this.$route.name === 'reel-slug' ? 'reels' : 'projects'}/by-slug?slug=${this.$route.params.slug}`)
          .then(({data}) => {
            this.data = data.data;
            console.log(data, 'test_projects');
          })
  },
  methods: {
    /*getYoutubeId(link){
      let id = link.match(/v=.+&/)[0];
      id = id.replace('v=', '');
      id = id.replace('&', '');
      return id;
    },*/
    switchRouteName() {
      switch (this.$route.name) {
        case 'portfolio-slug':
        return '/portfolio'
        case 'reel-slug':
          return '/reel'
        case 'home-slug':
          return '/'
          case 'services':
              return '/services/:slug'

      }
    },
    closeVideoPopup() {
      this.$router.push(this.switchRouteName());
    },
    resetDataPopup(type, pushRoute = true) {
      if (type === 'video') {
        this.videoId = '';
      } else {
        this.imageFull = {
          link: '',
          alt: '',
        }
      }
      if (pushRoute) {
        this.$router.replace({ query: {} })
      }
    },
  }
}
</script>

<style scoped>
.modal__video__mp{
  width: 80%;
  height: 80%;
  /*max-height: 70%;
  max-width: 70%;*/
}
.bounce-enter-active {
  animation: bounce-in 1s;
}
.bounce-leave-active {
  animation: bounce-in 1s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

</style>